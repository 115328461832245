import Matomo from '@components/Matomo';
import Script from 'next/script';
import React from 'react';

const CustomScripts = () => {
  return (
    <>
      <Matomo />
      <script
        async
        defer
        src="https://stable.loyjoy.com/widget.js?process=f6f8ccea-e9c7-4554-9324-a4bffa7ac728"
      ></script>
    </>
  );
};

export default CustomScripts;
